<template>
  <div class="email-bind bg-white">
    <van-form ref="form" validate-trigger="onSubmit" validate-first>
      <van-field
        v-model="email"
        name="email"
        label=""
        placeholder="请输入您的邮箱地址"
        :rules="[{ pattern, required: true, message: '邮箱格式不正确' }]"
      />
      <van-field
        class="setborder"
        v-model="verificationCode"
        name="verificationCode"
        label=""
        placeholder="验证码"
        :rules="[
          { validator: asyncValidator, message: '*获取验证码不正确请重新获取' },
        ]"
      >
        <template #button>
          <van-button
            native-type="button"
            @click="sendVerificationCode"
            v-show="primary"
            >获取验证码</van-button
          >
          <van-button
            native-type="button"
            @click="sendVerificationCode"
            v-show="!primary"
            :disabled="count > 0"
            class="reacquire"
          >
            重新获取
            <span v-show="count">{{ count }} 秒</span>
          </van-button>
        </template>
      </van-field>
    </van-form>
    <div class="bottom-btn">
      <van-button type="primary" @click="onClick">绑定</van-button>
    </div>
  </div>
</template>

<script>
import { sendBindEmailCode, bindEmail } from "../../../http/api";
export default {
  name: "email-bind",
  data() {
    return {
      email: "",
      verificationCode: "",
      count: 0,
      primary: true,
      timer: null,
      pattern: /^([a-zA-Z0-9]*[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
    };
  },
  methods: {
    sendVerificationCode() {
      this.$refs.form.validate("email").then(() => {
        this.primary = false;
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
        sendBindEmailCode(this.email).then((res) => {
          this.$toast.success("发送成功");
        });
      });
    },
    onClick() {
      this.$refs.form
        .validate()
        .then(() => {
          bindEmail({
            address: this.email,
            verificationCode: this.verificationCode,
          }).then((res) => {
            this.$toast.clear();
            this.$toast.success("绑定成功");
            this.$router.go(-1);
          });
        })
        .catch(() => {
          // do nothing
        });
    },
    asyncValidator(val) {
      return /^[A-Za-z0-9]{4}$/.test(val);
    },
  },
};
</script>

<style lang="less" scoped>
.email-bind {
  padding: 22px 8px 0;
  min-height: calc(100vh - 22px);
  .van-field {
    padding-top: 20px;
  }
  .van-field__button .van-button {
    width: 126px;
    height: 38px;
    padding: 0 27px;
    border-radius: 19px;
    border: 1px solid #06c05f;
    color: #06c05f;
    line-height: 20px;
    font-weight: 600;
  }
  .reacquire {
    background: #eefff6;
    color: #06c05f;
  }
  /deep/ .setborder {
    overflow: unset;
    .van-field__body {
      height: 100%;
      input {
        height: 100%;
        border-bottom: 1px solid #ebebeb;
      }
    }
    .van-field__button {
      padding-left: 25px;
      button {
        padding: 0;
      }
    }
    &.van-field--error {
      input {
        border-color: #f6404e;
      }
    }
    .van-field__error-message {
      position: absolute;
    }
  }
}
</style>
